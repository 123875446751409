<template>
  <div id="app" v-if="loaded">
    <div v-if="showNavbar" class="h-100 d-flex flex-column">
      <Navbar ref="navbar" />
      <router-view :class="leftMargin" style="flex: 2; overflow: hidden" />
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import config from "./config.js";
import axios from "axios";

import { mapGetters } from "vuex";

import firebase from "firebase/app";
import "firebase/auth";

export default {
  components: {
    Navbar: () => import("@/components/Navbar"),
  },
  data() {
    return {
      loaded: false,
      authState: false,
      prevAuthState: true,
    };
  },
  beforeCreate() {
    if (config.debug) window.firebase = firebase;

    axios
      .get(`${config.apiUrl}appConfig`)
      .then((config) => {
        this.$store.commit("appConfig", config.data);
        firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
      })
      .catch((err) => {
        console.error(err);
      });

    /*document.addEventListener(
      "touchmove",
      function(event) {
        event.preventDefault();
      },
      { passive: false }
    );*/
  },
  methods: {
    onAuthStateChanged: function (user) {
      if (user) {
        // Signed in
        // Get user id token and request user data from api
        let _idToken;
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            _idToken = idToken;
            // Fetch user data
            return axios.post(`${config.apiUrl}getUser`, {
              token: _idToken,
            });
          })
          .then((result) => {
            const userData = result.data;
            if (this.appConfig.mode !== 0) {
              if (this.appConfig.dashboardUID) {
                axios.post(`${config.apiUrl}dashboard/hasPermission`, {
                  uid: _idToken,
                  dashboardUID: this.appConfig.dashboardUID,
                });
              } else {
                throw new Error("No Dashboard UID Supplied!");
                //Set appMode to -1 here?
              }
            }
            if (this.appConfig.mode === 0) {
              if (this.appConfig.dashboardUID) {
                axios
                  .post(`${config.apiUrl}dashboard/hasPermission`, {
                    uid: _idToken,
                    dashboardUID: this.appConfig.dashboardUID,
                  })
                  .catch((err) => {
                    this.appConfig.mode = -1;
                    throw new Error("No permission", err);
                  });
              } else {
                if (!userData.admin) {
                  this.appConfig.mode = -1;
                  throw new Error("No permission to view this Dashboard");
                }
              }
            }
            userData.idToken = _idToken;
            this.$store.commit("signIn", userData);

            setInterval(this.refreshIdToken, 60000 * 50); //every 50min, force refresh idtoken
            // Get products to display
            if (this.appConfig.mode !== 0) {
              let myDashboardUID = this.appConfig.dashboardUID;
              if (!myDashboardUID) {
                return console.error("No Dashboard UID Supplied!");
              }
              return axios.post(`${config.apiUrl}database/getDashboardConfig`, {
                idToken: userData.idToken,
                dashboardUID: myDashboardUID,
              });
            } else {
              return axios.post(`${config.apiUrl}database/getAllProducts`, {
                idToken: _idToken,
                dashboardUID: this.appConfig.dashboardUID,
              });
            }
          })
          .then((result) => {
            if (result.data === "no data") {
              console.error("no data");
              throw new Error("no data");
              // this.appConfig.mode = -1;
            }
            let allProductCodes = [];
            let allPackagingCodes = [];
            let productMatrix = {};
            //this.$store.commit("productCodes", result.data);
            if (this.appConfig.mode !== 0) {
              for (
                var category = 0;
                category < result.data.length;
                category++
              ) {
                result.data[category].products.forEach((product) =>
                  allProductCodes.push(product)
                );
                result.data[category].packaging.forEach((packaging) =>
                  allPackagingCodes.push(packaging)
                );
                for (let key in result.data[category].productMatrix) {
                  productMatrix[key] = result.data[category].productMatrix[key];
                }
              }
              this.$store.commit("allProductCodes", allProductCodes);
              this.$store.commit("allPackagingCodes", allPackagingCodes);
              this.$store.commit("productMatrix", productMatrix);
            } else {
              this.$store.commit("allProductCodes", result.data);
            }
            // Handle sign in
            if (!this.prevAuthState) {
              // if user tried to access private page, it will save nextUrl params
              if (this.$route.params.nextUrl != null) {
                this.$router.push(this.$route.params.nextUrl);
              } else {
                this.$router.push("/");
              }
            }
            this.authState = true;
            this.prevAuthState = true;
            this.loaded = true;
          })
          .catch((err) => {
            console.error(err);
            firebase.auth().signOut();
          });
      } else {
        // Signed out
        this.$store.commit("signOut");
        if (this.authState) {
          this.$router.go();
        }
        this.authState = false;
        this.prevAuthState = false;
        this.loaded = true;
      }
    },

    refreshIdToken: function () {
      console.log("Refreshing idtoken");
      const user = firebase.auth().currentUser;
      if (user) {
        user.getIdToken(true).then((token) => {
          this.$store.commit("refreshIdToken", token);
        });
      }
    },
  },
  computed: {
    ...mapGetters(["appConfig"]),

    leftMargin: function () {
      // console.log();
      return this.$route.path.includes("/product") ? [] : ["ml-md-3", "ml-3"];
    },

    showNavbar: function () {
      return (
        this.$route.name !== "Login" &&
        this.$route.name !== "ForgotPassword" &&
        this.$route.name !== "ChangePassword"
      );
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  // overflow: hidden;
}

html {
  touch-action: none;
}

.simplebar-track {
  border-radius: 1em !important;
  background: #f1f1f1 !important;
  width: 1em !important;
}

.simplebar-scrollbar::before {
  border-radius: 1em !important;
  background-color: #c9c9c9 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  opacity: 1 !important;
  transition: all 1s linear !important;
}

.simplebar-content-wrapper {
  scroll-behavior: smooth !important;
}

.simplebar-dragging .simplebar-content-wrapper {
  scroll-behavior: unset !important;
}

/* The simplebar in Dashboard.vue */
div[data-v-22ba47ca] > .simplebar-track {
  margin-top: 3em !important;
  margin-bottom: 1.2em !important;
}

iframe {
  border: none !important;
  height: 200px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
