import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./custom.scss";

Vue.use(BootstrapVue, {
  breakpoints: [`xs`, "sm", "md", "lg", "xl", "xxl"]
});
Vue.use(IconsPlugin);
// Firebase
import firebase from "firebase/app";
import "firebase/analytics";
const firebaseConfig = require("@/firebaseConfig.json");

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.analytics().logEvent("testEvent");

/**
 * This is used to await user login, mainly for Vue router
 */
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

// Font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Screen size media queries
import VueMq from "vue-mq";
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity
  },
  defaultBreakpoint: "sm" // customize this for SSR
});

Vue.config.productionTip = false;

// Add Ammo library for physics
const loadModules = () =>
  new Promise(resolve => {
    const {
      wasmSupported,
      loadWasmModuleAsync
    } = require("./scripts/wasm-loader");
    if (wasmSupported()) {
      loadWasmModuleAsync(
        "Ammo",
        "https://firebasestorage.googleapis.com/v0/b/digitalchef-viewer/o/modules%2Fammo%2Fammo.wasm.js?alt=media&token=24686f4c-80bc-4a08-88fc-71bdec36b2bd",
        "https://firebasestorage.googleapis.com/v0/b/digitalchef-viewer/o/modules%2Fammo%2Fammo.wasm.wasm?alt=media&token=f8208924-d076-443d-b180-1699cad57d1d",
        () => {
          resolve();
        }
      );
    } else {
      loadWasmModuleAsync(
        "Ammo",
        "https://firebasestorage.googleapis.com/v0/b/digitalchef-viewer/o/modules%2Fammo%2Fammo.js?alt=media&token=a02fc4fd-10be-4f6d-9291-b122c3e46bde",
        "",
        () => {
          resolve();
        }
      );
    }
  });

loadModules().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");

  // HTML document extras
  document.body.onmousedown = function(e) {
    if (e.button == 1) return false;
  };

  function setVHFix() {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );
  }

  window.addEventListener("resize", setVHFix);
  window.addEventListener("orientationchange", setVHFix);
  setVHFix();
});
