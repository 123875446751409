import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // App
    appConfig: {},
    user: undefined,
    viewer: undefined,

    // Products
    productUIDs: [],
    productCodes: {},
    loadedProduct: undefined,
    packageLoaded: false,
    customizedPopdot: undefined,
    allProductCodes: [],
    allPackagingCodes: [],
    productProperties: {},
    productMatrix: [],

    // Sidebar
    sidebarState: false,

    // TabUpdater
    tabUpdater: 0
  },
  mutations: {
    // App
    appConfig: (state, data) => {
      state.appConfig = data;
    },
    signIn: (state, data) => {
      state.user = data;
    },
    refreshIdToken: (state, token) => {
      state.user.idToken = token;
    },
    signOut: state => {
      state.user = undefined;
    },
    setViewer: (state, data) => {
      state.viewer = data;
    },

    // Products
    productUIDs: (state, data) => {
      state.productUIDs.push(...data);
    },

    productCodes: (state, data) => {
      state.productCodes = data;
    },

    loadedProduct: (state, data) => {
      state.loadedProduct = data;
    },

    packageLoaded: (state, data) => {
      state.packageLoaded = data;
    },

    customizedPopdot: (state, data) => {
      state.customizedPopdot = data;
    },

    allProductCodes: (state, data) => {
      state.allProductCodes = data;
    },

    allPackagingCodes: (state, data) => {
      state.allPackagingCodes = data;
    },

    productProperties: (state, data) => {
      state.productProperties = data;
    },

    productMatrix: (state, data) => {
      state.productMatrix = data;
    },

    // Sidebar
    toggleSidebar: (state, data) => {
      if (data !== undefined) {
        state.sidebarState = data;
      } else {
        state.sidebarState = !state.sidebarState;
      }
    },

    // TabUpdater
    tabUpdater: state => state.tabUpdater++
  },
  getters: {
    // App
    appConfig: state => state.appConfig,
    user: state => state.user,
    viewer: state => state.viewer,

    // Products
    productUIDs: state => state.productUIDs,

    //Products to load initially
    productCodes: state => state.productCodes,
    loadedProduct: state => state.loadedProduct,
    packageLoaded: state => state.packageLoaded,
    customizedPopdot: state => state.customizedPopdot,
    allProductCodes: state => state.allProductCodes,
    allPackagingCodes: state => state.allPackagingCodes,
    productProperties: state => state.productProperties,
    productMatrix: state => state.productMatrix,

    // Sidebar
    sidebarState: state => state.sidebarState,

    // TabUpdater
    tabUpdater: state => state.tabUpdater
  },
  actions: {},
  modules: {}
});
