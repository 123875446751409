import Vue from "vue";
import VueRouter from "vue-router";

import firebase from "firebase/app";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Popdot Configurator",
    meta: {
      requiresAuth: true
    },
    component: () => import("@/views/PopdotConfiguratorPage.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/LoginPage.vue")
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () => import("@/views/PasswordReset.vue")
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: () => import("@/views/PasswordChange.vue")
  },
  // {
  //   name: "Product",
  //   path: "/product/:id",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //     { path: "", component: () => import("@/views/ProductPage_Viewer.vue") },
  //     {
  //       path: "edit",
  //       component: () => import("@/views/ProductPage_Editor.vue")
  //     }
  //   ],
  //   component: () => import("@/views/ProductPage.vue")
  // },
  // {
  //   name: "Upload",
  //   path: "/upload",
  //   meta: {
  //     requiresAuth: true
  //   },
  //   component: () => import("@/views/Upload.vue")
  // }
  // {
  //   path: "/testivo",
  //   name: "Test",
  //   component: () => import("@/views/TestPage.vue"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await firebase.getCurrentUser();
  // console.log("route change", to.fullPath, currentUser ? currentUser.email : null);
  if (to.fullPath === "/login" && currentUser) {
    // login page while logged in
    return next("/");
  } else if (to.fullPath.includes("/product")) {
    // product request without argument
    if (to.matched.length === 0) {
      return next("/");
    }
  }

  // Check meta for required authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log("route check auth");
    if (!currentUser) {
      // console.log("redirect to login");
      return next({
        name: "Login",
        params: { nextUrl: to.fullPath }
      });
    }
  }
  // console.log("pass route");
  return next();
});

export default router;
